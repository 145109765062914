export const ChooseUsCards = {
  RESPONSIBILITY: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704457810/Main/icon-park-outline_success_zsobl6.svg',
    heading: 'Responsibility',
    text: 'Successfully completed projects in healthcare across various stages and domains.'
  },
  SUPPORT: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704457809/Main/material-symbols_album-outline_qzy8j6.svg',
    heading: 'Dedicated Support',
    text: 'We are oriented on a result and long term cooperation'
  },
  QUALITY: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704457809/Main/material-symbols_cycle_u37a3y.svg',
    heading: 'Quality Assurance',
    text: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in healthcare applications.'
  },
  SOLUTIONS: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704457809/Main/Control_camera_awi6dl.svg',
    heading: 'Innovative Solutions',
    text: 'Driving Innovation through Prototyping, PoC, and MVP Development'
  }
}

export const ChooseUsDataEN = {
  ENTERTAINMENT: {
    firstParagraph: 'At TetaLab, we\'re dedicated to bringing your entertainment concept to life, from the start to the moment it empowers your first user, and beyond.  Your success stories become our reputation for excellence in building impactful applications.',
    secondParagraph: 'With a proven track record, we\'ve helped numerous entertainment MVPs from ideation to launch, providing ongoing support throughout their journey.',
    responsibilityText: 'Successfully completed entertainment projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in entertainment applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  // HEALTHCARE: { //TODO temporary commented Healthcare
  //   firstParagraph: 'At TetaLab, we are committed to nurturing your healthcare concept from its inception to the first patient interaction and far beyond, where your successes seamlessly contribute to our healthcare reputation.',
  //   secondParagraph: 'With a proven history, we\'ve successfully guided healthcare MVP projects from ideation to impactful product releases, providing continuous support throughout their healthcare journey.',
  //   responsibilityText: 'Successfully completed projects in healthcare across various stages and domains.',
  //   dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
  //   qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in healthcare applications.',
  //   innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  // },
  AI: {
    firstParagraph: "At TetaLab, we unlock the power of AI to turn your innovative ideas into groundbreaking solutions. We'll work with you from concept to deployment, ensuring your AI applications drive positive changes.",
    secondParagraph: "With a proven track record, we've helped numerous AI MVPs from ideation to launch, providing ongoing support throughout their journey.",
    responsibilityText: 'Successfully completed AI projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in AI applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  UTILS : {
    firstParagraph: "At TetaLab, we're dedicated to bringing your tool and utility concept to life, from the start to the moment it empowers your first user, and beyond.  Your success stories become our reputation for excellence in building impactful applications.",
    secondParagraph: "With a proven track record, we've helped numerous tools and utilities MVPs from ideation to launch, providing ongoing support throughout their journey.",
    responsibilityText: 'Successfully completed tools and utilities projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in tools and utilities applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  EDUCATION: {
    firstParagraph: "At TetaLab, we are dedicated to empowering your educational endeavor from the moment of conception to the first student interaction, and long after. Your successes seamlessly contribute to our esteemed reputation in the education industry.",
    secondParagraph: "Bring your tourism vision to life. Leverage our expertise in guiding MVPs from concept to captivating experiences. We'll be your partner every step of the way, ensuring your tourism success story unfolds.",
    responsibilityText: 'Successfully completed education projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in education applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  ORDERING: {
    firstParagraph: "At TetaLab, we are committed to nurturing your ordering and delivery concept from its inception to the first customer interaction and far beyond. We have successfully guided ordering MVP projects from ideation to impactful product releases.",
    secondParagraph: "Reliability and customer trust are paramount to us, and we strive to ensure that your success strengthens our reputation as a reliable partner in the ordering and delivery domain.",
    responsibilityText: 'Successfully completed projects in ordering across stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in ordering applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  TOURISM: {
    firstParagraph: "At TetaLab, we are dedicated to nurturing your tourism venture every step of the way, from its inception to the first traveler interaction, and far beyond. Your successes seamlessly contribute to our esteemed reputation in the tourism industry.",
    secondParagraph: "Turn your tourism vision into reality. Our proven track record in guiding MVPs from concept to captivating user experiences ensures ongoing support throughout your tourism success story.",
    responsibilityText: 'Successfully completed tourism projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in travel and hospitality applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
  IOT: {
    firstParagraph: "At TetaLab, we specialize in transforming your IoT concepts into reality, from initial idea to deployment and beyond. Your success stories drive our reputation for excellence in creating impactful IoT solutions.",
    secondParagraph: "With a proven track record, we've helped numerous IoT MVPs from ideation to launch, providing ongoing support throughout their journey.",
    responsibilityText: 'Successfully completed IoT projects spanning various stages and sectors.',
    dedicatedSupportText: 'We are oriented on a result and long term cooperation.',
    qualityAssuranceText: 'Our rigorous quality assurance ensures accuracy and reliability, setting the benchmark for excellence in IoT applications.',
    innovativeSolutionsText: 'Driving Innovation through Prototyping, PoC, and MVP Development.',
  },
};

export const ChooseUsDataUA = {
  ENTERTAINMENT: {
    firstParagraph: 'В TetaLab ми з натхненням перетворюємо ваші задуми щодо розваг на реальність – від самого початку і до того моменту, коли вони захоплюють ваших перших користувачів, і далі. Ваші історії успіху – це наша запорука бездоганності у створенні вражаючих додатків.',
    secondParagraph: 'Завдяки нашим успішним кейсам, ми допомогли багатьом розважальним MVP пройти шлях від ідеї до запуску, надаючи їм постійну підтримку на всіх етапах.',
    responsibilityText: 'Успішно завершені проєкти в галузі розваг на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в розважальних додатках.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  HEALTHCARE: {
    firstParagraph: 'У TetaLab ми присвячуємо себе розвитку вашої ідеї в галузі охорони здоров\'я від самого її зародження до першої взаємодії з пацієнтом і далі, де ваші успіхи безперешкодно сприяють зміцненню нашої репутації у сфері охорони здоров’я.',
    secondParagraph: 'Ми успішно керували проектами MVP у сфері охорони здоров\'я від ідеї до важливих випусків продуктів, забезпечуючи постійну підтримку протягом їхнього шляху в галузі охорони здоров\'я.',
    responsibilityText: 'Успішно завершені проекти в галузі охорони здоров\'я з багатьма етапами та в різних доменах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в медичних додатках.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  AI: {
    firstParagraph: "У TetaLab ми використовуємо потужність ШІ, щоб перетворити ваші інноваційні ідеї на революційні рішення. Ми будемо співпрацювати з вами від концепції до впровадження, гарантуючи, що ваші програми ШІ вестимуть до реальних змін.",
    secondParagraph: "Завдяки нашим успішним кейсам, ми допомогли багатьом MVP з ШІ пройти шлях від ідеї до запуску, надаючи їм постійну підтримку на всіх етапах.",
    responsibilityText: 'Успішно завершені проєкти в галузі розваг на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в додатках з ШІ.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  UTILS : {
    firstParagraph: "У TetaLab ми прагнемо втілити у життя вашу концепцію інструменту чи службової програми, від початку до моменту, коли вона розширить можливості вашого користувача, і далі. Ваші історії успіху підсилюють нашу репутацію у сфері  створення ефективних додатків.",
    secondParagraph: "Маючи значний досвід, ми допомогли розробити MVP у сфері інструментів та службових програм в процесі від ідеї до запуску, надаючи постійну підтримку протягом їхнього шляху.",
    responsibilityText: 'Успішно завершені проєкти в галузі інструментів та службових програм на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в інструментах та службових додатках.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  EDUCATION: {
    firstParagraph: "У TetaLab ми підтримуємо ваш освітній проект на кожному етапі його розвитку, від задуму до першої взаємодії зі студентом і далеко за її межі. Ваші успіхи безпосередньо сприяють нашій бездоганній репутації в освітній галузі.",
    secondParagraph: "Перетворіть свою туристичну мрію на реальність. Наш успішний досвід у супроводженні MVP від концепції до захоплюючого користувацького досвіду гарантує постійну підтримку протягом вашої туристичної історії успіху.",
    responsibilityText: 'Успішно завершені проєкти в галузі освіти на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в освітніх додатках.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  ORDERING: {
    firstParagraph: "У TetaLab ми віддані розвитку вашого концепту замовлень і доставки від самого початку до першої взаємодії з клієнтом і далі. Ми успішно керували MVP-проектами в індустрії замовлень від стадії ідеї до впливових продуктових релізів.",
    secondParagraph: "Надійність і довіра клієнтів є пріоритетом для нас, і ми працюємо над тим, щоб ваш успіх зміцнював нашу репутацію як надійного партнера в галузі замовлень.",
    responsibilityText: 'Успішно завершені проекти у сфері систем управління замовленнями на різних етапах та у різних секторах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в системах управління замовленнями.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  TOURISM: {
    firstParagraph: "В TetaLab ми залучені у розвиток вашого туристичного проєкту на кожному етапі, від початку до першої взаємодії з мандрівником і далі. Ваші успіхи сприяють нашій високій репутації в галузі туризму.",
    secondParagraph: "Перетворіть ваше бачення на реальність. Наш досвід у супроводі MVP від концепції до захоплюючого користувацького досвіду, гарантує постійну підтримку протягом вашої історії успіху в туризмі.",
    responsibilityText: 'Успішно завершені проєкти в галузі туризму на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в додатках для туризму та готельної індустрії.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
  IOT: {
    firstParagraph: "В TetaLab ми спеціалізуємося на перетворенні ваших IoT концепцій на реальність від початку до впровадження та подальшої підтримки. Ваші історії успіху підсилюють нашу репутацію у створенні ефективних IoT рішень.",
    secondParagraph: "Маючи значний досвід, ми допомогли розробити MVP у сфері IoT від ідеї до запуску, надаючи постійну підтримку протягом їхнього шляху.",
    responsibilityText: 'Успішно завершені проєкти в галузі IoT на різних етапах та в різних сферах.',
    dedicatedSupportText: 'Ми орієнтовані на результат та довгострокову співпрацю.',
    qualityAssuranceText: 'Наша система ретельного контролю якості гарантує точність і надійність, встановлюючи стандарт якості в IoT додатках.',
    innovativeSolutionsText: 'Впроваджуємо інновації через створення прототипів, PoC та розробку MVP.',
  },
}

export const SolutionsSteps = {
  1: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704461964/Main/Icon_ninmrm.svg',
    heading: 'Introductory call',
    text: 'Schedule your free 30-minute consultation session to explore your requirements and challenges.'
  },
  2: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704461963/Main/Icontre_gt6kyk.svg',
    heading: 'Project Discussion',
    text: 'Delve into the details of your project, addressing key aspects and considerations for a successful collaboration.'
  },
  3: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704461963/Main/rtetete_gx05po.svg',
    heading: 'Tailored Solutions',
    text: 'Explore customized solutions to meet your specific needs and overcome challenges effectively.'
  },
  4: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704461962/Main/trtetetetr_c6e7an.svg',
    heading: 'Commence Collaboration',
    text: 'Embark on a journey of collaboration, implementing solutions that align with your goals and aspirations.'
  }
}

export const CallToActionsData = {
  ANDROID: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1699944728/Frame_1_mkoej0.svg',
    heading: 'Bring Your Idea to Life with Android',
    text: "We're here to help you bring your app ideas to reality. Whether it's a startup concept or an enterprise solution, our team has the expertise to craft a top-notch Android app tailored to your needs."
  },
  FLUTTER: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718097481/Main/4800226_1_vp5hl6.svg',
    heading: 'Bring Your Vision to Life with Flutter',
    text: "Let us assist you in turning your app concepts into a vibrant reality. Whether it's a startup innovation or an enterprise solution, our team is well-equipped to design a premium Flutter app that aligns perfectly with your requirements."
  },
  IOS: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1700047569/Group_pzqtyn.svg',
    heading: 'Bring Your Vision to Life with iOS',
    text: "Let us assist you in turning your app concepts into a vibrant reality. Whether it's a startup innovation or an enterprise solution, our team is well-equipped to design a premium iOS app that aligns perfectly with your requirements."
  },
  REACT: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704204522/Main/Frame_e2xnjl.svg',
    heading: "Unlock React's Power",
    text: "Transform your ideas with our React development team. Partner with us to harness React's power and bring your digital concepts to life. Whether it's a robust web app or responsive mobile solution, let's build something extraordinary together!"
  },
  NODE: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704205234/Main/4849787_1_iyuljg.svg',
    heading: 'Ignite your vision with Node.js',
    text: "Our team is geared to turn your ideas into robust and fast applications, be it for a startup or an enterprise. Join us, and let's create something extraordinary!"
  },
  IOT: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704440126/Main/Group_p6xv9t.svg',
    heading: 'Start Building IoT Solutions',
    text: 'Start your journey to make your ideas come true with our easy-to-use IoT solutions. Use our help to make your projects better and bigger. Begin building your IoT solutions with us and discover new opportunities.'
  },
  // HEALTHCARE: { //TODO temporary commented Healthcare
  //   ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704442405/Main/Frame_o104gu.svg',
  //   heading: 'Future-Ready Health Solutions',
  //   text: "Partner with us for cutting-edge software development and design expertise. Let's innovate together to shape the future of healthcare."
  // },
  TOURISM: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704469580/Main/Group_fy1s3b.svg',
    heading: 'Empower Your Business with Our Travel Tech Solutions!',
    text: 'Boost efficiency with our innovative web and mobile apps, hotel management systems, and tailored solutions. Let our expertise elevate your business. Contact us today for a brighter future in travel tech!'
  },
  ORDERING: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704470849/Main/43543_pvrgxg.svg',
    heading: 'Transform Your Business with Seamless Ordering Solutions!',
    text: 'Explore efficient ordering solutions tailored to your business. Whether in food, retail, or services, our systems optimize processes, elevate satisfaction, and fuel growth. Embrace success through innovation!'
  },
  EDUCATION: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704472181/Main/gy45y44y4_hvjgmb.svg',
    heading: 'Unlock the Potential of Educational Excellence',
    text: 'Transform your educational institution with our cutting-edge software solutions. From streamlined administration to enhanced communication, we tailor systems to elevate your academic experience. Take the first step toward educational innovation and success – connect with us today!'
  },
  TOOLS: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704470541/Main/Objects_luek8d.svg',
    heading: 'Streamline Operations with Tailored Tools & Utilities',
    text: 'Unlock new possibilities for your business with our tailored and efficient software development solutions in the Tools & Utilities domain. Reliability, efficiency, and innovation are the keys to success for your project. Contact us today and embark on a new stage of your own success.'
  },
  ENTERTAINMENT: {
    ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717417511/Group_70791_ulsues.svg',
    heading: 'Dive into Entertainment Software Development with us',
    text: "Transform ideas into captivating digital experiences — from innovative games to cutting-edge programs. Let's shape the future of entertainment together. Explore the possibilities today."
  },
  AI: {
    ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Frame_1_tib9un.svg',
    heading: 'Transform Your Challenges into AI-Driven Opportunities',
    text: "We'll tailor AI-powered applications that streamline processes, optimize workflows, and unlock new possibilities for your organization."
  },
}

export const ServicesHeaders = {
  ANDROID: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1699878368/Frame_hvvygk.svg',
    heading: 'Android Technology',
    text: 'Empower your ideas with our Android development expertise. We specialize in creating high-performance Android applications, ready to bring your ambitious plans to life. Join us to unlock the full potential of Android Technology.'
  },
  IOS: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1700052536/3759387_1_ekhz9b.svg',
    heading: 'iOS Technology',
    text: 'Boost your concepts with our iOS development proficiency. We excel in crafting top-notch iOS applications, poised to breathe life into your visionary projects. Partner with us to unleash the complete power of iOS Technology.'
  },
  REACT: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1700052543/Frame_3_blltyp.svg',
    heading: 'React Technology',
    text: 'Elevate your ideas with our React expertise. Our team specializes in creating cutting-edge applications using React, delivering seamless and dynamic user experiences. Join forces with us to harness the full potential of React Technology for your projects.'
  },
  NODE: {
    ico: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1700052543/Frame_3_blltyp.svg',
    heading: 'Node.js Technology',
    text: 'Empower your digital ventures with our Node.js expertise. Our adept team specializes in harnessing the full potential of Node.js to develop robust and scalable applications. Join forces with us to bring your innovative ideas to life using the cutting-edge capabilities of Node.js technology.'
  },
  FLUTTER: {
    ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718095945/Main/Frame_fazaep.svg',
    heading: 'Flutter Technology',
    text: 'Transform your idea into an app with our Flutter development expertise. We excel at creating high-quality applications that bring your vision to life. Partner with us to fully leverage the power of Flutter technology.'
  },
}

export const ServicesSolutions = {
  ANDROID: [
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473203/Main/carbon_container-software_dxjfb0.svg',
      heading: 'Mobile App Development',
      text: 'Designing and developing mobile applications for smartphones and tablets that run on the Android operating system.'
    },
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473191/Main/carbon_container-software7647467475_j28wqu.svg',
      heading: 'Wearable Apps',
      text: 'Developing applications for wearable devices such as smartwatches, designed to work with Android Wear.'
    },
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473201/Main/carbon_container-software45_dc9ald.svg',
      heading: 'App Components',
      text: 'Developing different application components, including activities, services, content providers, and broadcasters.'
    },
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473196/Main/carbon_container-software786768_vwhu3c.svg',
      heading: 'Integration',
      text: 'Integrating services like Google Maps, Firebase, and others to add more functionalities.'
    },
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473200/Main/carbon_container-software534654_wyhcy1.svg',
      heading: 'Testing and Debugging',
      text: "Testing and troubleshooting applications to ensure they're of high quality and run smoothly."
    },
    {
      category: 'Android',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473199/Main/656465464_shhmxy.svg',
      heading: 'Deployment',
      text: 'Launching applications on Google Play Store, and maintaining and updating them.'
    },
  ],
  IOS: [
    {
      category: 'iOS',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473198/Main/carbon_container-software87685_t1z50s.svg',
      heading: 'Native iOS Apps',
      text: "Crafting bespoke iOS applications tailored for iPhones and iPads, our team specializes in leveraging the unique features and capabilities offered by Apple's iOS operating system. From user-friendly interfaces to seamless integration with iOS functionalities, we bring your ideas to life in the Apple ecosystem."
    },
    {
      category: 'iOS',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473197/Main/carbon_container-software75657_kbjrh2.svg',
      heading: 'Custom iOS Apps',
      text: 'Creating custom iOS applications is our forte. We specialize in tailoring apps specifically for iPhones and iPads, ensuring they meet your unique requirements and seamlessly integrate with the iOS environment. Trust us to bring your custom app ideas to reality on the Apple platform.'
    },
    {
      category: 'iOS',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473197/Main/carbon_container-software75867856_cy4cdz.svg',
      heading: 'iOS Apps Design',
      text: "Crafting captivating designs for iOS applications is our expertise. We focus on creating visually appealing and user-friendly interfaces tailored to the iOS ecosystem. Our goal is to enhance the overall user experience and bring your app's design to life on Apple devices."
    },
    {
      category: 'iOS',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473196/Main/carbon_container-software786768_vwhu3c.svg',
      heading: 'App Store Compliance',
      text: "Ensuring App Store compliance is a key aspect of our iOS app development process. We meticulously adhere to Apple's guidelines, guaranteeing that your application meets all necessary standards for a seamless submission and approval on the App Store."
    }
  ],
  REACT: [
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473195/Main/carbon_container-software7697565756475_htzdmp.svg',
      heading: 'Web Application Development',
      text: 'Crafting high-performance and interactive web applications for various industries.'
    },
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473203/Main/carbon_container-software_dxjfb0.svg',
      heading: 'Mobile Applications',
      text: 'Developing cutting-edge mobile apps for both iOS and Android platforms using React Native'
    },
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473197/Main/carbon_container-software75867856_cy4cdz.svg',
      heading: 'Adaptive Design',
      text: 'Implementation of interfaces that work seamlessly on different devices and screen sizes.'
    },
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473201/Main/carbon_container-software45_dc9ald.svg',
      heading: 'Reactive Components',
      text: 'Delivering client advantages through high-quality and dynamic reactive components.'
    },
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473196/Main/carbon_container-software786768_vwhu3c.svg',
      heading: 'Integration with Other Technologies',
      text: 'Seamlessly integrating various services and technologies using React.'
    },
    {
      category: 'FrontEnd',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473194/Main/carbon_container-software76567575_ezcrns.svg',
      heading: 'Technical Support and Optimization',
      text: 'Providing support services, extending functionality, and optimizing existing projects.'
    },
  ],
  NODE: [
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473193/Main/carbon_container-software765765765_utmkc2.svg',
      heading: 'Backend Development',
      text: 'Crafting powerful and scalable server-side applications for seamless data management and processing.'
    },
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473192/Main/carbon_container-software7657577657_xpcnng.svg',
      heading: 'API Development',
      text: 'Building robust APIs to facilitate communication between different software applications, ensuring smooth integration and interoperability.'
    },
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473192/Main/carbon_container-software65i86356754_ptz2rl.svg',
      heading: 'Custom Solutions',
      text: 'Tailor-made applications to meet specific business needs, ensuring optimal performance and user experience.'
    },
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473191/Main/carbon_container-software7647467475_j28wqu.svg',
      heading: 'Real-Time Applications',
      text: 'Developing responsive and interactive real-time applications, ideal for chat applications, gaming platforms, and live streaming.'
    },
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473191/Main/carbon_container-software67644_xpp4dk.svg',
      heading: 'Consultation and Integration',
      text: 'Expert guidance on adopting Node.js into existing systems or developing strategies for new projects.'
    },
    {
      category: 'Backend',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473194/Main/carbon_container-software76567575_ezcrns.svg',
      heading: 'Maintenance and Support',
      text: 'Continuous support for your Node.js applications, handling updates, bug fixes, and performance optimization.'
    },
  ],
  FLUTTER: [
    {
      category: 'Flutter',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473193/Main/carbon_container-software765765765_utmkc2.svg',
      heading: 'Cross-Platform Development',
      text: "Our team specializes in cross-platform development using Flutter, ensuring your app runs smoothly on both iOS and Android. We provide a seamless user experience across different devices."
    },
    {
      category: 'Flutter',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473192/Main/carbon_container-software7657577657_xpcnng.svg',
      heading: 'Turnkey App Development',
      text: "We provide comprehensive turnkey development services, handling everything from concept to deployment. Our team manages every detail to ensure your app is delivered on time and meets all requirements."
    },
    {
      category: 'Flutter',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473203/Main/carbon_container-software_dxjfb0.svg',
      heading: 'App Design',
      text: "Our design team creates intuitive and visually appealing interfaces tailored to your target audience. We focus on user-centric design principles to enhance user engagement and satisfaction."
    },
    {
      category: 'Flutter',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473197/Main/carbon_container-software75657_kbjrh2.svg',
      heading: 'App Store and Play Market Submission',
      text: "We manage the submission process for both the App Store and Play Market, ensuring your app meets all guidelines. Our team handles compliance checks and final submissions, guaranteeing a smooth launch."
    }
  ],
}

export const DomainsSolutions = {
  IOT: [
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477907/Main/ic_software_fiidyv.svg',
      heading: 'Software Development IoT Solutions',
      text: 'We create customized IoT solutions to connect devices and collect data, integrating advanced analytics and intelligent software to provide actionable insights. Our innovative approach optimizes efficiency and drives progress across industries.',
    },
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477908/Main/ic_home_gszsj2.svg',
      heading: 'Smart Home, Office IoT Solutions',
      text: 'Our company creates easy-to-use Smart Home and Office Solutions. We tailor the software to enhance homes and workplaces. With a simple mix of user-friendly interfaces and modern features, we design solutions that smoothly connect devices, letting users easily control their surroundings, changing the way homes and offices work.'
    },
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477908/Main/ic_city_mm6ogw.svg',
      heading: 'Smart City IoT Solutions',
      text: 'For Smart City IoT solutions, our company is like a digital problem-solver, creating software that makes city life easier. We design simple solutions that connect various sensors and devices, aiming to improve how cities work and help people live better.'
    },
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477908/Main/ic_solution_q9peml.svg',
      heading: 'Automotive IoT Solutions',
      text: 'Our company specializes in developing software to improve the user experience while driving and optimize vehicle performance. We pay special attention to creating intuitive interfaces and seamless integration with automotive systems. Our custom IT solutions aim to enhance safety, efficiency and comfort on the road. We strive to bring the transport industry to a new level of innovation by enabling interaction between vehicles and infrastructure.'
    },
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477908/Main/ic_industrial_vlhmvr.svg',
      heading: 'Industrial IoT Solutions',
      text: 'We specialize in creating user-friendly interfaces and seamless integration with industrial systems and machinery. Our tailored solutions aim to optimize production, minimize downtime, and improve overall productivity. By enabling smart communication and data analysis, we empower industries to achieve greater levels of automation and performance, driving innovation and competitiveness in the industrial sector.'
    },
    {
      category: 'IoT',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704756432/Main/carbon_application-web_lennlp.svg',
      heading: 'System on Chip IoT Solutions',
      text: 'We prioritize efficiency and scalability, crafting solutions that optimize the use of resources on these compact chips. Our goal is to unlock the full potential of SoC devices, enabling seamless integration of IoT functionality into a wide range of applications. Through our specialized SoC IoT solutions, we aim to empower businesses and individuals to leverage the power of connected devices in a cost-effective and efficient manner.'
    },
  ],
  // HEALTHCARE: [ //TODO temporary commented Healthcare
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477910/Main/ic_management_em4bpd.svg',
  //     heading: 'Hospital and Medical Center Management Systems',
  //     text: 'Developing software for automating management processes in hospitals and medical centers.'
  //   },
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477910/Main/ic_management_em4bpd.svg',
  //     heading: 'Health and Internet of Things (IoT) Integration',
  //     text: 'Developing systems that interact with medical devices and sensors for data collection and processing'
  //   },
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473203/Main/carbon_container-software_dxjfb0.svg',
  //     heading: 'Health Mobile Applications',
  //     text: 'Creating mobile apps for health monitoring, interacting with medical services, and more.'
  //   },
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473203/Main/carbon_container-software_dxjfb0.svg',
  //     heading: 'Patient Portals',
  //     text: 'Creating web portals that allow patients to access their medical data and interact with medical staff.'
  //   },
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473197/Main/carbon_container-software75867856_cy4cdz.svg',
  //     heading: 'Medical App Design',
  //     text: 'Crafting intuitive and user-friendly interfaces for medical applications.'
  //   },
  //   {
  //     category: "Healthcare",
  //     ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704473194/Main/carbon_container-software76567575_ezcrns.svg',
  //     heading: 'Maintenance and Support',
  //     text: 'Ensuring the seamless operation of healthcare software beyond development.'
  //   },
  // ],
  TOURISM: [
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477910/Main/ic_hotel_ridsav.svg',
      heading: 'Hotel Booking and Management',
      text: 'Developing systems for online hotel booking and hotel operations management.'
    },
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477910/Main/ic_hotel_ridsav.svg',
      heading: 'Payment and Financial Systems',
      text: 'Developing secure payment systems and financial tools tailored for the tourism industry.'
    },
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477909/Main/ic_system_mdyqae.svg',
      heading: 'Tour Package Management Systems',
      text: 'Developing programs for managing tour packages, encompassing elements such as transportation, accommodation, tours, and other services.'
    },
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477908/Main/ic_transport_rhkqjp.svg',
      heading: 'Transportation System Integration',
      text: 'Designing solutions that integrate with transportation systems to provide convenient access to various modes of transportation.'
    },
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477907/Main/ic_analytic_s8fouq.svg',
      heading: 'Analytics and Reporting',
      text: 'Building analytics systems for tourism companies to gather and analyze data on user behavior, bookings, and other crucial metrics.'
    },
    {
      category: 'Tourism',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477909/Main/ic_online_paku1y.svg',
      heading: 'Tourist Online Portals',
      text: 'Designing web and mobile applications for travelers to access destination information, make reservations, and plan trips.'
    },
  ],
  ORDERING: [
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477906/Main/ic_menu_mcfxyl.svg',
      heading: 'Electronic Menus and Ordering',
      text: 'Development of mobile apps and web platforms for online food or product ordering.'
    },
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477906/Main/ic_pos_m4lgau.svg',
      heading: 'E-commerce Platforms',
      text: 'Development of online stores and electronic platforms for selling goods and services'
    },
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477909/Main/ic_payment_huklaz.svg',
      heading: 'Order Management Systems',
      text: 'Automation of order processing and fulfillment processes. Integration with payment systems and delivery services.'
    },
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477906/Main/ic_pos_m4lgau.svg',
      heading: 'Solutions for Restaurants',
      text: 'Point of Sale (POS) services and integration with kitchen equipment. Inventory management and staff management.'
    },
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477906/Main/ic_tracking_ffyicf.svg',
      heading: 'Mobile Apps for Delivery Services',
      text: 'Development of apps for couriers and delivery services. Order tracking and delivery route monitoring.'
    },
    {
      category: 'Ordering Systems',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477907/Main/ic_analytic_s8fouq.svg',
      heading: 'Analytics and Reporting',
      text: 'Reporting tools to assess the popularity and effectiveness of products.'
    },
  ],
  EDUCATION: [
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477904/Main/ic_platform_rl7j98.svg',
      heading: 'Learning Management Systems',
      text: 'Development of platforms that facilitate course management, content delivery, and student assessment.'
    },
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477905/Main/ic_e-platform_jjpijm.svg',
      heading: 'E-learning Platforms',
      text: 'Creation of interactive digital platforms for online courses, tutorials, and educational content.'
    },
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477905/Main/ic_e-apps_bb4mhx.svg',
      heading: 'Educational Apps',
      text: 'Developing mobile applications to support learning through interactive content, quizzes, and gamification.'
    },
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477905/Main/ic_adaptive_iuihfu.svg',
      heading: 'Adaptive Learning Systems',
      text: 'Designing solutions that adapt to individual student needs, providing personalized learning paths.'
    },
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477904/Main/ic_assessment_qlgvd0.svg',
      heading: 'Assessment and Testing Software',
      text: 'Creating platforms for creating, conducting, and grading assessments and tests.'
    },
    {
      category: 'Education',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477904/Main/ic_school_r1xo9e.svg',
      heading: 'School Management Software',
      text: 'Creating complete school administration systems, managing attendance, schedules, and communication.'
    },
  ],
  TOOLS: [
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477912/Main/ic_custom_fa8qtp.svg',
      heading: 'Custom Software Tools',
      text: 'Development of individual software tools tailored to your specific needs.'
    },
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477912/Main/ic_utilities_vcjdbf.svg',
      heading: 'Utilities Optimization',
      text: 'Enhancing the efficiency and functionality of household and corporate software utilities.'
    },
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477912/Main/ic_integration_pxqzgn.svg',
      heading: 'Integration Services',
      text: 'Integration of software solutions to ensure their collaboration and interaction.'
    },
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477912/Main/ic_automationtreretre_p4bmhy.svg',
      heading: 'Automation Solutions',
      text: 'Development of automated tools to simplify routine tasks.'
    },
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477911/Main/ic_functionality_j47oof.svg',
      heading: 'Enhanced Tools Functionality',
      text: 'Expanding the functionality of tools through software enhancements.'
    },
    {
      category: 'Utils',
      ico: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704477911/Main/ic_monitoring_sxvfb8.svg',
      heading: 'Performance Monitoring',
      text: 'Systems for monitoring and analyzing the performance of various tools.'
    },
  ],
  ENTERTAINMENT: [
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415285/Group_game_ygtkbs.svg',
      heading: 'Game Design',
      text: 'Developing game concepts, including gameplay, scripts, characters, and worlds.'
    },
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415284/Group_programming_rk6hjd.svg',
      heading: 'Programming',
      text: 'Writing code to implement gameplay, player interaction, and other game aspects.'
    },
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415284/Vector_design_dqjyi7.svg',
      heading: 'Graphic Design',
      text: 'Creating graphic elements, including characters, backgrounds, animations, and more.'
    },
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415284/Vector_sound_df7gyn.svg',
      heading: 'Sound Design',
      text: 'Creating sound effects, music, and audio elements to enhance gameplay.'
    },
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415283/Group_platforms_gld5vb.svg',
      heading: 'Platforms and Distribution',
      text: 'Choosing platforms on which the game will be available and developing distribution strategies.'
    },
    {
      category: 'Entertainment',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717415283/Vector_optimization_elhnl1.svg',
      heading: 'Optimization and Support',
      text: 'Ensuring optimal game performance and providing support for users.'
    },
  ],
  AI: [
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449788/Vector_31_yj4pcf.svg',
      heading: 'AI App Development',
      text: 'Building custom AI-powered applications tailored to your specific business needs.'
    },
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Group_70793_whzmhe.svg',
      heading: 'AI Chatbot Development',
      text: 'Crafting intelligent chatbots that engage with users in natural language conversations.'
    },
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Frame_taxn1e.svg',
      heading: 'AI Website Integration',
      text: 'Integrating AI capabilities into websites, transforming them into intelligent platforms.'
    },
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Group_70794_cejwme.svg',
      heading: 'Machine Learning and AI',
      text: 'Implementing machine learning models to solve your unique challenges.'
    },
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Group_70792_qlc3bf.svg',
      heading: 'AI Process Automation',
      text: 'Automating repetitive tasks to free up your time for more strategic work.'
    },
    {
      category: 'AI',
      ico: 'https://res.cloudinary.com/dc4kiugor/image/upload/v1717449787/Vector_32_ra06p0.svg',
      heading: 'Industry-Specific AI Solutions',
      text: 'Designing and deploying tailored AI solutions for your industry.'
    },
  ]
}

export const ProjectsData = {
  PLANT_CARE_APP: {
    id: "plantcare-app",
    heading: 'PlantCare app',
    title: 'How our startup client gained 110,000 users and grew 45% MOM through the use of our IOS and ANDROID development services',
    subtitle: '',
    img: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1699619165/Frame_70590_yfq7zn.svg'
  },
  TRAILER_SECURITY_APP: {
    id: "trailer_Watchdog",
    heading: 'Trailer Security app',
    title: 'Keep your trailer safe and in a good shape',
    subtitle: 'Embark on a seamless security journey with our Android app and IoT integration, designed specifically for a prominent USA company. Specializing in trailer tracking, our innovative solution ensures real-time monitoring of critical metrics. In the event of any deviations from the norm, the app promptly alerts the owner, offering proactive insights into potential trailer issues. Experience unparalleled security and peace of mind with our cutting-edge technology at your fingertips.',
    img: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704204098/Main/Slide_2_wxctda.png'
  },
  ANDROID_POS: {
    id: "android_POS",
    heading: 'Android POS',
    title: 'How to make Automated POS system for fruits and veggies shops',
    subtitle: 'Discover our cutting-edge app, created in partnership with a Belgian startup revolutionizing small shop automation. Simplify your shopping experience through efficient self-management and streamlined payments, reducing costs and enhancing store operations. Our app offers features like quick goods weighing, inventory tracking, equipment management, faster checkouts, and detailed statistics on user purchases. Step into the future of seamless and cost-effective retail with our application.',
    img: 'https://res.cloudinary.com/dq9w8bxge/image/upload/v1699619242/Slide_3_igzdmp.svg'
  },
  // HEALTHCARE_PLATFORM: { //TODO temporary commented Healthcare
  //   id: "healthcare_platform",
  //   heading: 'Healthcare Platform',
  //   title: 'Streamlining Prescription & Appointment Scheduling',
  //   subtitle: 'Streamlined solution designed to enhance efficiency in administrative tasks, improve communication with patients, facilitate patient management, and ensure data protection, all while seamlessly integrating with common PVS practice calendars:',
  //   img: 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1704204198/Main/Slide_3_metd1q.png'
  // }
}

export const paths = {
  mainUa: '/ua',
  mainEn: '/en',
  domains: '/domains',
  domainsHash: '/#domains',
  services: '/services',
  android: '/android',
  about: '/#about',
  portfolioPage: '/portfolio',
  careersPage: '/careers',
  forStartups: '/for-startups',
  blog: '/blog',
  IoT: '/iot',
  contactUs: '/#contact-us',
  admin: '/admin',
  adminArticles: '/admin/articles',
}

export const capitalizeFirstLetter = (input) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}
