import React from "react";
import { Trans } from "react-i18next";
import { motion } from "framer-motion";

import Ticker from "./Ticker";

export const Counter = () => {
  const coffeeCounter = () => {
    const date1 = new Date("2023-01-01T00:00:00Z")
    const date2 = new Date()
    return 12345 + Math.round(Math.abs(date1 - date2) / 36e5);
  };

  return (
    <motion.div
      initial={{ x: -300, opacity: 0, scale: 1 }}
      whileInView={{ x: 0, opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="counter"
    >
      <div className="counter-wrapper">
        <div className="counter-block">
          <div className="counter-item">
            <Ticker className="counter-number"
                    start={0} end={5}
            />
            <p className="counter-text"><Trans>years of work</Trans></p>
          </div>
          <div className="counter-item">
            <Ticker className="counter-number"
                    start={0} end={100} suffix="+"
            />
            <p className="counter-text"><Trans>completed projects</Trans></p>
          </div>
        </div>
        <div className="counter-block">
          <div className="counter-item">
            <Ticker className="counter-number"
                    start={0} end={50} suffix="+"
            />
            <p className="counter-text"><Trans>happy customers</Trans></p>
          </div>
          <div className="counter-item">
            <Ticker className="counter-number"
                    start={0} end={coffeeCounter()} suffix="+"
            />
            <p className="counter-text"><Trans>coffee cups</Trans></p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
