import React from 'react';
import { motion } from "framer-motion";
import { Trans } from "react-i18next";
import { MemoizedContactForm } from "../../Components/ContactUsForm";
import { useParams } from "react-router-dom";

function MainContactUs({ whiteBg }) {
  const { lang} = useParams();
  
  return (
    <div className="sect" id="contact-us">
      <div className={`sect-container ${whiteBg && `whiteBg`}`}>
        <MemoizedContactForm/>
      </div>
    </div>
  );
}

export default MainContactUs;