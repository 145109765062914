import React from 'react';
import {motion} from "framer-motion";
import {Trans} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { paths } from "../../Constants";

function MainServices() {
    const {lang} = useParams();

    return (
        <div className="sect" id="services">
            <div className="sect-container">
                <motion.div
                    initial={{x: 0, opacity: 0, scale: 1}}
                    whileInView={{x: 0, opacity: 1, scale: 1}}
                    viewport={{once: true}}
                    transition={{duration: 0.5}}
                    className="sect-title"
                >
                    <Trans>Services</Trans>
                </motion.div>
                <div className="sect-cards">
                    <Link to={`/${lang}${paths.portfolioPage}?category=UI/UX`}>
                        <div id="ui/ux-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="ui/ux"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535377/Main/UX_Design_sikc5t.svg"
                                />
                            </LazyLoad>
                            <p><Trans>UI/UX Design</Trans></p>
                        </div>
                    </Link>
                    <Link to={`/${lang}${paths.services}/android`}>
                        <div id="android-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="android"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535357/Main/Android_apps_rdlcng.svg"
                                />
                            </LazyLoad>
                            <p><Trans>Android apps</Trans></p>
                        </div>
                    </Link>
                    <Link to={`/${lang}${paths.services}/ios`}>
                        <div id="ios-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="ios"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/iOS_apps_n99kqz.svg"
                                />
                            </LazyLoad>
                            <p><Trans>iOS apps</Trans></p>
                        </div>
                    </Link>
                    <Link to={`/${lang}${paths.domains}/iot`}>
                        <div id="hardware-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="hardware"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535380/Main/Hardware_csve5n.svg"
                                />
                            </LazyLoad>
                            <p><Trans>Hardware</Trans></p>
                        </div>
                    </Link>
                    <Link to={`/${lang}${paths.services}/react`}>
                        <div id="frontEnd-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="frontEnd"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535379/Main/FrontEnd_lg7cyd.svg"
                                />
                            </LazyLoad>
                            <p>FrontEnd</p>
                        </div>
                    </Link>
                    <Link to={`/${lang}${paths.services}/node`}>
                        <div id="backEnd-card" className="sect-cards-card service-cards-item bg-white">
                            <LazyLoad>
                                <img alt="backEnd"
                                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535378/Main/BackEnd_wqigcr.svg"
                                />
                            </LazyLoad>
                            <p>BackEnd</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MainServices;