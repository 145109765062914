import React from 'react';
import {motion} from "framer-motion";
import {Trans} from "react-i18next";
import {MemoizedCarousel} from "../../Components/Carousel";
import {Projects_en} from "../../Constants/Projects";
import {useNavigate, useParams} from "react-router-dom";

function MainPortfolio() {
  const navigate = useNavigate();
  const {lang} = useParams();

  return (
    <div className="sect" id="portfolio">
      <div className="sect-container">
        <div className="sect-title slider-title">
          <motion.p
            initial={{x: 0, opacity: 0, scale: 1}}
            whileInView={{x: 0, opacity: 1, scale: 1}}
            viewport={{once: true}}
            transition={{duration: 0.5}}
          >
            <Trans>Portfolio</Trans>
          </motion.p>
          <motion.button
            initial={{x: 100, opacity: 0, scale: 1}}
            whileInView={{x: 0, opacity: 1, scale: 1}}
            viewport={{once: true}}
            transition={{duration: 0.5}}
            className="sect-button view-all-btn"
            onClick={() => navigate(`/${lang}/portfolio`)}
          >
            <Trans>View All</Trans>
          </motion.button>
        </div>
        <MemoizedCarousel arr={Projects_en} bg={"bg-white"}/>
      </div>
    </div>
  );
}

export default MainPortfolio;