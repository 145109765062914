export const Countries = [
    "USA",
    "France",
    "Austria",
    "Belgium",
    "German",
    "Ukraine",
    "Romania",
    "Israel",
    "India",
    "Australia",
    "UK",
    "Sweden"
];
