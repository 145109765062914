import React from 'react';
import { motion } from "framer-motion";
import { Trans } from "react-i18next";
import { MemoizedFeedbackCarousel } from "../../Components/FeedbackCarousel";
import { useParams } from "react-router-dom";

function MainFeedback({ beigeBg }) {
  const { lang } = useParams();
  
  return (
    <div className={`sect ${beigeBg && `beigeBg`}`} id="feedbacks">
      <div className="sect-container">
        <div className="sect-title slider-title">
          <motion.p
            initial={{ x: 0, opacity: 0, scale: 1 }}
            whileInView={{ x: 0, opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <Trans>What Our Clients Say</Trans>
          </motion.p>
        </div>
        <MemoizedFeedbackCarousel/>
      </div>
    </div>
  );
}

export default MainFeedback;