import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "@formspree/react";

export function ContactForm({
    className,
    inputClassName,
    labelClassName,
}) {
    const {i18n} = useTranslation();

    const [state, handleSubmit] = useForm("mknnpwov");
    // const [state, handleSubmit] = useForm("mbjwvdnq");

    const [formValues, setFormValues] = useState({
        fullName: "",
        email: "",
        message: ""
    });

    const [errors, setErrors] = useState({
        fullName: null,
        email: null,
        message: null
    });

    const validate = () => {
        let valid = true;
        const newErrors = { fullName: null, email: null, message: null };

        if (!formValues.fullName.trim()) {
            newErrors.fullName = "Full Name is required";
            valid = false;
        }

        if (!formValues.email.trim()) {
            newErrors.email = "Email is required";
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = "Email is invalid";
            valid = false;
        }

        if (!formValues.message.trim()) {
            newErrors.message = "Message is required";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            handleSubmit(event);
        }
    };

    if (state.succeeded) {
        return <div className="success-message"><Trans>Success!</Trans></div>
    }

    return (
        <div className={`main-sect-form ${className || ''}`} id="contact-us">
            <div className="project-contactus-title-wrapper">
                <h2 className="contactus-title"><Trans>Contact us</Trans></h2>
                <p className="contactus-plain-text">
                    <Trans
                        i18nKey="Leave an email to schedule a free 30 minutes consulting session."
                        components={{
                            highlight: <span className="contactus-highlight" />,
                        }}
                    />
                </p>
            </div>

            <form onSubmit={handleFormSubmit} className="main-form">
                <div className="main-form-content">
                    <label htmlFor="fullName" className={`main-sect-form-label ${labelClassName}`}>
                        <Trans>Full Name</Trans>
                        <input id="fullName"
                               type="text"
                               name="fullName"
                               placeholder={i18n.t("Full Name")}
                               value={formValues.fullName}
                               maxLength="20"
                               onChange={(event) => setFormValues({
                                   ...formValues,
                                   fullName: event.target.value
                               })}
                               className={`contact-us-email ${inputClassName}`}
                        />
                        {errors.fullName && <span className="error-message"><Trans>{errors.fullName}</Trans></span>}
                    </label>
                    
                    <label htmlFor="email" className={`main-sect-form-label ${labelClassName}`}>
                        Email
                        <input id="email" className={`contact-us-email ${inputClassName}`}
                               type="email" name="email"
                               placeholder={i18n.t("Email")}
                               value={formValues.email}
                               maxLength="30"
                               onChange={(event) => setFormValues({
                                   ...formValues,
                                   email: event.target.value
                               })}
                        />
                        {errors.email && <span className="error-message"><Trans>{errors.email}</Trans></span>}
                    </label>
                    
                    <label htmlFor="message" className={`main-sect-form-label ${labelClassName}`}>
                        <Trans>Your message</Trans>
                        <textarea
                          id="message"
                          name="message"
                          placeholder={i18n.t("Message")}
                          value={formValues.message}
                          maxLength="250"
                          onChange={(event) => setFormValues({
                              ...formValues,
                              message: event.target.value
                          })}
                          className={`contact-us-email ${inputClassName}`}
                        />
                        {errors.message && <span className="error-message"><Trans>{errors.message}</Trans></span>}
                    </label>
                    
                    <button
                      className={`form-button`}
                      type="submit"
                      aria-label="submit"
                      disabled={!(formValues.fullName && formValues.email && formValues.message)}
                    >
                        <Trans>Send Message</Trans>
                    </button>
                </div>
            </form>
        </div>
    );
}

export const MemoizedContactForm = React.memo(ContactForm)