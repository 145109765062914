import React from 'react';
import {motion} from "framer-motion";
import {Trans} from "react-i18next";
import {CareersList} from "../../mock-data/mock-data";
import {Link, useNavigate, useParams} from "react-router-dom";
import LazyLoad from "react-lazy-load";
import {MemoizedCarousel} from "../../Components/Carousel";

function MainCareers() {
  const navigate = useNavigate();
  const {lang} = useParams();

  return (
    <div className="sect" id="careers">
      <div className="sect-container">
        <div className="sect-title slider-title">
          <motion.p
            initial={{x: 0, opacity: 0, scale: 1}}
            whileInView={{x: 0, opacity: 1, scale: 1}}
            viewport={{once: true}}
            transition={{duration: 0.5}}
          >
            <Trans>Join Us</Trans>
          </motion.p>
          <motion.button
            initial={{x: 100, opacity: 0, scale: 1}}
            whileInView={{x: 0, opacity: 1, scale: 1}}
            viewport={{once: true}}
            transition={{duration: 0.5}}
            className="sect-button view-all-btn"
            onClick={() => navigate(`/${lang}/careers`)}
          >
            <Trans>View All</Trans>
          </motion.button>
        </div>
        {CareersList.length <= 1 ?
          CareersList.map((career, i) =>
            <div className="sect-cards" key={career.id + i}>
              <Link
                to={`/${lang}/careers/${career.id}`}
                state={{career}}
                key={`careers/${career.id}`}
              >
                <div className="sect-cards-card sect-cards-card-careers bg-gray">
                  <LazyLoad>
                    <img
                      alt="ui/ux"
                      className="portfolio-img"
                      src={career.img}
                    />
                  </LazyLoad>
                  <p>{career.title}</p>
                </div>
              </Link>
            </div>
          )
          :
          <MemoizedCarousel arr={CareersList} bg={"bg-gray"}/>
        }
      </div>
    </div>
  );
}

export default MainCareers;