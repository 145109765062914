import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as DownArrowSVG } from '../Assets/Icons/arrow_down.svg';
import { ReactComponent as UkrainianFlagSVG } from '../Assets/Icons/flag_ua.svg';
import { ReactComponent as EnglishFlagSVG } from '../Assets/Icons/flag_en.svg';

import { useBreakpoints } from '../utils/useBreakpoints';

import '../Assets/Styles/LanguageChanger.css';
import { AnimatePresence, motion } from "framer-motion";

const LanguageChanger = ({ isDomainsPage, isLangDropdownOpen = null, setIsLangDropdownOpen = null }) => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { pathname } = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(isLangDropdownOpen || false);
  const {isXlCustomScreen} = useBreakpoints();
  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
    setIsDropdownOpen(false);
    if (setIsLangDropdownOpen !== null) {
      setIsLangDropdownOpen({
        ...isLangDropdownOpen,
        service: false,
        domains: false,
        lang: false
      })
    }
  };
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (setIsLangDropdownOpen !== null && isLangDropdownOpen !== null) {
      setIsLangDropdownOpen(
        {
          ...isLangDropdownOpen,
          service: false,
          domains: false,
          lang: !isLangDropdownOpen.lang
        }
      )
    }
  };
  
  return (
    <div className="language-changer">
      <div className={`current-language ${(isDomainsPage && isXlCustomScreen) ? "arrow-white" : ''}`} onClick={toggleDropdown}>
        {i18n.language === 'en' ? <EnglishFlagSVG/> : <UkrainianFlagSVG/>}
        <DownArrowSVG/>
      </div>
      
      <AnimatePresence>
        {(isLangDropdownOpen !== null ? isLangDropdownOpen.lang : isDropdownOpen) && (
          <motion.div
            initial={{ x: 0, opacity: 0, scale: 1 }}
            whileInView={{ x: 0, opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="language-dropdown">
              <Link
                to={pathname.replace(/\b(en|ua)\b/, 'en')}
                onClick={() => changeLanguage('en')}
              >
                <EnglishFlagSVG/>
              </Link>
              
              <Link
                to={pathname.replace(/\b(en|ua)\b/, 'ua')}
                onClick={() => changeLanguage('ua')}
              >
                <UkrainianFlagSVG/>
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default memo(LanguageChanger);
